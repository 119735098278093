import { Accordion } from 'react-bootstrap';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from "react-router-dom";
import ImageComponent from "../../images"
import { checkRolePermission, getBaseUrl, isCompanyEnable, typeCheck, isPermissionChecked, isApplicationTypeChecked, getActiveClass } from 'utils';
import SidebarController from './sidebarController';
import { routeKey } from "constant/routeKey"
import { companySlug, scopeSlug } from "constant"
import { openSidebar } from 'store/commonData/commonSlice';
import ButtonComponent from 'component/forms/button';

const SidebarLayout = () => {
    const {
        dataCheck,
        handleLogout,
        applicationTypeStatus,
        location,
        loginDetails,
        isSidebarOpen,
        dispatch,
        scopeType,
        handleToggleApplication
    } = SidebarController()
    const permissionsDto = loginDetails?.data?.permissionsData || []


    return (
        <div data-testid="sidebar-layout" className={`mainsidebar ${isSidebarOpen ? "" : "closeSidebar"}`}>
            <div className="sidebarLogo">
                <ImageComponent path="/images/login/greensightLogo.png" className="greensightLogo img-fluid" />
                <ImageComponent handleOnClick={() => dispatch(openSidebar(!isSidebarOpen))} path="/images/hamburgerClose.svg" className={`closedIcon img-fluid ${isSidebarOpen ? "" : "d-none"}`} />
            </div>
            <div className="sidebarnav-wrapper">
                <Accordion>
                    <Nav className="flex-column">

                        {isApplicationTypeChecked(loginDetails?.data?.permissionsData || [], routeKey.AdministratorAccess) && (
                            <div className="togglwSwitch-btn">
                                <ButtonComponent
                                    onClick={() =>
                                        handleToggleApplication(!applicationTypeStatus)
                                    }
                                    btnClass="btn-deepgreen font-12 font-xxl-14 py-2 flex-row-reverse gap-2 togle-switch justify-content-start text-start"
                                    imagePath="/images/header/switch-admin.svg"
                                    text={
                                        applicationTypeStatus
                                            ? "Switch to Dashboard"
                                            : "Switch to Admin"
                                    }
                                />
                            </div>
                        )}
                        {!applicationTypeStatus && scopeType === scopeSlug?.scope3 && <>
                            <NavItem className="position-relative mb-2">
                                <NavLink to={getBaseUrl(loginDetails?.data, scopeType)} className={getActiveClass(["/regional-level", "/sustainable"], location, true)}>
                                    <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Dashboard">
                                        <ImageComponent path="/images/sidebar/dashboardicon.svg" />
                                        <h4 className="mb-0 navText">Dashboard</h4>
                                    </div>
                                </NavLink>
                            </NavItem>

                            {isPermissionChecked(permissionsDto, routeKey?.Segmentation)?.isChecked &&
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={location.pathname !== "/regional-level" ? getActiveClass(["/regional", "/business-unit", "/region-overview", "/lanes", "/ev-dashboard", "/master-dashboard", "/ev-network", "/facility", "/carrier", "/RNG", "/optimus"], location, true) : ''}>
                                        <div >
                                            <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Track">
                                                <ImageComponent path="/images/sidebar/trackIcon.svg" />
                                                <h4 className="mb-0 navText">Track</h4>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className='pe-0 ps-3'>
                                        <Nav className="">
                                            {!checkRolePermission(loginDetails?.data, "regionalManager") && <NavItem>
                                                <NavLink to="/regional" className={typeCheck((location.pathname !== "/regional-level" && location.pathname.includes("/region")), "active", "")}>
                                                    <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="By Region">
                                                        <ImageComponent path="/images/sidebar/byregion.svg" />
                                                        <h4 className="mb-0 navText navTextChild">By Region</h4>
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                            }
                                            {!isCompanyEnable(dataCheck?.userdata, [companySlug?.pep, companySlug?.adm, companySlug?.tql]) && <NavItem>
                                                <NavLink to="/facility" className={getActiveClass(["/facility"], location, false)}>
                                                    <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="By Facility">
                                                        <ImageComponent path="/images/sidebar/byfacility.svg" />
                                                        <h4 className="mb-0 navText navTextChild">By Facility</h4>
                                                    </div>
                                                </NavLink>
                                            </NavItem>}

                                            {isCompanyEnable(dataCheck?.userdata, [companySlug?.pep]) && <>
                                                <NavItem>
                                                    <NavLink to="/business-unit" className={getActiveClass(["/business-unit"], location, false)}>
                                                        <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="By Business Unit">
                                                            <ImageComponent path="/images/sidebar/byfacility.svg" />
                                                            <h4 className="mb-0 navText navTextChild">By Business Unit</h4>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink to="/division" className={getActiveClass(["/division"], location, false)}>
                                                        <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="By Division">
                                                            <ImageComponent path="/images/sidebar/bydivision.svg" />
                                                            <h4 className="mb-0 navText navTextChild">By Division</h4>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            </>
                                            }
                                            {!isCompanyEnable(dataCheck?.userdata, [companySlug?.adm, companySlug?.tql]) && <NavItem>
                                                <Accordion className="newaccordian">
                                                    <Accordion.Item eventKey="2">
                                                        <Accordion.Header className="pe-0">
                                                            <Nav>
                                                                <NavItem>
                                                                    <NavLink to="/carrier" className={getActiveClass(["/carrier"], location, false)}>
                                                                        <div className="d-flex align-items-center gap-2 p-0 navitemtxtWrapper " data-toggle="tooltip" data-placement="right" title="By Carrier">
                                                                            <ImageComponent path="/images/sidebar/bycarrier.svg" />
                                                                            <h4 className="mb-0 navText navTextChild">By Carrier</h4>
                                                                        </div>
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                        </Accordion.Header>
                                                        <Accordion.Body className="ps-3">
                                                            <Nav>
                                                                <NavItem>
                                                                    <NavLink to="/carrier-comparison" className="py-2 pb-0">
                                                                        <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper pb-1 pt-0" data-toggle="tooltip" data-placement="right" title="Carrier Comparision">
                                                                            <ImageComponent path="/images/sidebar/carrierComparision.svg" />
                                                                            <h4 className="mb-0 navText navTextChild">Carrier Comparison</h4>
                                                                        </div>
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </NavItem>}

                                            <NavItem>
                                                <NavLink to="/lanes" className={location.pathname.includes("/lanes") ? "active py-0" : " py-0"}>
                                                    <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="By Lane">

                                                        <ImageComponent path="/images/sidebar/bylane.svg" />
                                                        <h4 className="mb-0 navText navTextChild">By Lane</h4>
                                                    </div>
                                                </NavLink>
                                            </NavItem>



                                            {isCompanyEnable(dataCheck?.userdata, [companySlug?.adm]) && <NavItem>
                                                <NavLink to="/fuel" className={getActiveClass(["/fuel"], location, false)}>
                                                    <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="By Fuel">
                                                        <ImageComponent path="/images/sidebar/byFuel.svg" />
                                                        <h4 className="mb-0 navText navTextChild">By Fuel</h4>
                                                    </div>
                                                </NavLink>
                                            </NavItem>}

                                            {isCompanyEnable(dataCheck?.userdata, [companySlug?.tql]) && <NavItem>
                                                <NavLink to="/trailer" className={getActiveClass(["/trailer"], location, false)}>
                                                    <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="By Trailer">
                                                        <ImageComponent path="/images/sidebar/byTrail.svg" />
                                                        <h4 className="mb-0 navText navTextChild">By Trailer</h4>
                                                    </div>
                                                </NavLink>
                                            </NavItem>}

                                            {isCompanyEnable(dataCheck?.userdata, [companySlug?.adm]) && <NavItem>
                                                <NavLink to="/vehicle" className={getActiveClass(["/vehicle"], location, false)}>
                                                    <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="By Vehicle">
                                                        <ImageComponent path="/images/sidebar/byVehicle.svg" />
                                                        <h4 className="mb-0 navText navTextChild">By Vehicle</h4>
                                                    </div>
                                                </NavLink>
                                            </NavItem>}

                                            {(isCompanyEnable(dataCheck?.userdata, [companySlug?.pep]) && isPermissionChecked(permissionsDto, routeKey?.EvDashboard)?.isChecked) &&
                                                <NavItem className="">
                                                    <NavLink to="/master-dashboard" className={typeCheck((location.pathname === "/master-dashboard" || location.pathname.includes("/ev-dashboard")), "active", "")}>
                                                        <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="EV Dashboard">
                                                            <ImageComponent path="/images/sidebar/ev-dashboard.svg" />
                                                            <h4 className="mb-0 navText navTextChild">EV Dashboard</h4>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>}
                                            {isCompanyEnable(dataCheck?.userdata, [companySlug?.pep]) && (
                                                <>
                                                    <NavItem className="">
                                                        <NavLink to="/optimus" className={location.pathname.includes("/optimus") ? "active py-0" : " py-0"}>
                                                            <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="EV Network">
                                                                <ImageComponent path="/images/sidebar/optimus.svg" />
                                                                <h4 className="mb-0 navText navTextChild">Optimus Network</h4>
                                                            </div>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="">
                                                        <NavLink to="/ev-network" className={location.pathname.includes("/ev-network") ? "active py-0" : " py-0"}>
                                                            <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="EV Network">
                                                                <ImageComponent path="/images/sidebar/ev.svg" />
                                                                <h4 className="mb-0 navText navTextChild">EV Network</h4>
                                                            </div>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="">
                                                        <NavLink to="/alternative-fuel" className={location.pathname.includes("/Alternative") ? "active py-0" : " py-0"}>
                                                            <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Alternative Fuel">
                                                                <ImageComponent path="/images/sidebar/byFuel.svg" />
                                                                <h4 className="mb-0 navText navTextChild">Alternative Fuels</h4>
                                                            </div>
                                                        </NavLink>
                                                    </NavItem>
                                                </>
                                            )}
                                        </Nav>
                                    </Accordion.Body>
                                </Accordion.Item>}
                            {isPermissionChecked(permissionsDto, routeKey?.Benchmarks)?.isChecked &&
                                <NavItem className="position-relative mb-2">
                                    <NavLink to="/benchmarks" className={getActiveClass(["/benchmarks"], location, true)}>
                                        <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Benchmark">
                                            <ImageComponent path="/images/sidebar/benchmarksIcon.svg" />
                                            <h4 className="mb-0 navText">Benchmarks</h4>
                                        </div>
                                    </NavLink>
                                </NavItem>}
                            {isApplicationTypeChecked(permissionsDto, routeKey?.Recommendations) &&
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className={getActiveClass(["/decarb", "/lane-planning", "/bid-", "/reports"], location, true)}>
                                        <div >
                                            <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Act">
                                                <ImageComponent path="/images/sidebar/act.svg" />
                                                <h4 className="mb-0 navText">Act</h4>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className='ps-3'>
                                        <Nav className="d-flex flex-column">
                                            <NavItem>
                                                <NavLink to="/decarb" className={getActiveClass(["/decarb"], location, false)}>
                                                    <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Decarb Levers">
                                                        <ImageComponent path="/images/sidebar/decarb.svg" />
                                                        <h4 className="mb-0 navText navTextChild">Decarb Levers</h4>
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink to="/lane-planning" className={getActiveClass(["/lane-planning"], location, false)}>
                                                    <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Lane Planning">
                                                        <ImageComponent path="/images/sidebar/laneScenario.svg" />
                                                        <h4 className="mb-0 navText navTextChild">Lane Planning</h4>
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                            {isPermissionChecked(permissionsDto, routeKey?.BidPlanning)?.isChecked && <NavItem>
                                                <NavLink to="/bid-planning" className={getActiveClass(["/bid-"], location, false)}>
                                                    <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Lane Planning">
                                                        <ImageComponent path="/images/sidebar/bidPlanning.svg" />
                                                        <h4 className="mb-0 navText navTextChild">Bid Planning</h4>
                                                    </div>
                                                </NavLink>
                                            </NavItem>}
                                            {isCompanyEnable(dataCheck?.userdata, [companySlug?.pep]) &&
                                                <NavItem>
                                                    <NavLink to="/reports" className={getActiveClass(["/report"], location, false)}>
                                                        <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Reports">
                                                            <ImageComponent path="/images/sidebar/reports.svg" />
                                                            <h4 className="mb-0 navText navTextChild">Reports</h4>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            }
                                        </Nav>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                            {isPermissionChecked(permissionsDto, routeKey?.Manage)?.isChecked &&

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className={(location.pathname.includes("/project") ? "activebar" : "")}>
                                        <div >
                                            <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Manage">

                                                <ImageComponent path="/images/sidebar/manageIcon.svg" />
                                                <h4 className="mb-0 navText">Manage</h4>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className='ps-3'>
                                        <Nav className="d-flex flex-column">
                                            <NavItem>
                                                <NavLink to="/projects" className={location.pathname.includes("/project") ? "active" : ""}>
                                                    <div className="d-flex align-items-center gap-2 childGap navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Projects">
                                                        <ImageComponent path="/images/sidebar/project.svg" />
                                                        <h4 className="mb-0 navText navTextChild">Projects</h4>
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }

                            <NavItem className="position-relative mb-2">
                                <NavLink to="/settings" className={(navData) => (navData.isActive ? 'activebar active' : '')}>
                                    <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="User Settings">
                                        <ImageComponent path="/images/sidebar/iconWhite-6.svg" />
                                        <h4 className="mb-0 navText">User Settings</h4>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem className="position-relative mb-2">
                                <NavLink to="/knowledge-hub" className={(navData) => (navData.isActive ? 'activebar active' : '')}>
                                    <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Knowledge Hub">
                                        <ImageComponent path="/images/sidebar/knowledge.svg" />
                                        <h4 className="mb-0 navText">Knowledge Hub</h4>
                                    </div>
                                </NavLink>
                            </NavItem>

                        </>}

                        {!applicationTypeStatus && scopeType === scopeSlug?.scope1 && <>
                            <NavItem className="position-relative mb-2">
                                <NavLink to={getBaseUrl(loginDetails?.data, scopeType)} className={getActiveClass(["/scope1/dashboard"], location, true)}>
                                    <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Dashboard">
                                        <ImageComponent path="/images/sidebar/dashboardicon.svg" />
                                        <h4 className="mb-0 navText">Dashboard</h4>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem className="position-relative mb-2 opacity-50">
                                <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="User Settings">
                                    <ImageComponent path="/images/sidebar/iconWhite-6.svg" />
                                    <h4 className="mb-0 navText">User Settings</h4>
                                </div>
                            </NavItem>
                        </>}

                        {applicationTypeStatus && <>
                            <NavItem className="position-relative mb-2 opacity-50">
                                <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Comming Soon">
                                    <ImageComponent path="/images/sidebar/dashboardicon.svg" />
                                    <h4 className="mb-0 navText">Dashboard</h4>
                                </div>
                            </NavItem>
                            {isPermissionChecked(permissionsDto, routeKey?.UserManagement)?.isChecked &&

                                <Accordion.Item eventKey="4" className="position-relative mb-2">
                                    <Accordion.Header className={(location.pathname.includes("/user-management") ? "activebar barPosition" : "")}>
                                        <Nav>
                                            <NavItem>
                                                <NavLink to="/user-management" className={typeCheck(location.pathname.includes("/user"), "active", "")}>
                                                    <div className="d-flex align-items-center gap-2 p-0 navitemtxtWrapper " data-toggle="tooltip" data-placement="right" title="User Management">
                                                        <ImageComponent path="/images/sidebar/userManagement.svg" />
                                                        <h4 className="mb-0 navText pe-0">User Management</h4>
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Accordion.Header>
                                    {isPermissionChecked(permissionsDto, routeKey?.RoleManagement)?.isChecked &&

                                        <Accordion.Body>
                                            <Nav className="d-flex flex-column ">
                                                <NavItem>
                                                    <NavLink to="/role-management" className={location.pathname.includes("/role-management") ? "activebar barPosition" : ""}>
                                                        <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Role Management">
                                                            <ImageComponent path="/images/sidebar/roleManagement.svg" />
                                                            <h4 className="mb-0 navText role-txt">Role Management</h4>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Accordion.Body>}
                                </Accordion.Item>}
                            {isPermissionChecked(permissionsDto, routeKey?.DataManagement)?.isChecked &&

                                <NavItem className="position-relative mb-2">
                                    <NavLink to="/data-management" className={(navData) => (navData.isActive ? 'activebar' : '')}>
                                        <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Data Management">
                                            <ImageComponent path="/images/sidebar/dataManagement.svg" />
                                            <h4 className="mb-0 navText pe-0">Data Management</h4>
                                        </div>
                                    </NavLink>
                                </NavItem>
                            }
                            {isCompanyEnable(dataCheck?.userdata, [companySlug?.pep]) && <NavItem className="position-relative mb-2">
                                <NavLink to="/lane-setting" className={(navData) => (navData.isActive ? 'activebar' : '')}>
                                    <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Lane Settings">
                                        <ImageComponent path="/images/sidebar/laneSetting.svg" />
                                        <h4 className="mb-0 navText pe-0">Lane Settings</h4>
                                    </div>
                                </NavLink>
                            </NavItem>}
                            <NavItem className="position-relative mb-2 opacity-50">
                                <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Comming Soon">
                                    <ImageComponent path="/images/sidebar/appManagement.svg" />
                                    <h4 className="mb-0 navText">Application Management</h4>
                                </div>
                            </NavItem>
                        </>}
                        <NavItem>
                            <NavLink to={'/'}>
                                <div className="d-flex align-items-center gap-2 mb-0 navitemtxtWrapper" onClick={handleLogout} data-toggle="tooltip" data-placement="right" title="Logout">
                                    <ImageComponent path="/images/sidebar/logouticon.svg" />
                                    <h4 className="mb-0 navText">Logout</h4>
                                </div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Accordion>
            </div>
            <div className="scope-wrapper">
                <ImageComponent path="/images/powered-by.svg" className="pe-0" />
            </div>
        </div>
    )
}

export default SidebarLayout